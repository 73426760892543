const envPicker = (key) => process.env[key] || undefined

let thirdPartyToken = envPicker('REACT_APP_API_THIRD_PARTY_TOKEN')
const apiEndPoint = envPicker('REACT_APP_API_ENDPOINT_URL')
const CLIENT_API_BASE_URL = envPicker('REACT_APP_CLIENT_API_BASE_URL')
const IS_ENCRYPTED = envPicker('REACT_APP_ENCRYPTION_MODE')
const ENCRYPTION_VALUE = envPicker('REACT_APP_ENCRYPTION_VALUE')
const ENCRYPTION_KEY = envPicker('REACT_APP_ENCRYPTION_KEY')
const IS_JIRAN = envPicker('REACT_APP_IS_JIRAN')
const HMAC_SECRET_KEY = envPicker('REACT_APP_HMAC_SECRET_KEY')
const IS_MULTI_GROUP = envPicker('REACT_APP_IS_MULTI_GROUP')

const getEnv = () => {

  return {
    API_ENDPOINT_URL: apiEndPoint,
    THIRD_PARTY_TOKEN: thirdPartyToken,
    CLIENT_API_BASE_URL,
    IS_ENCRYPTED,
    ENCRYPTION_VALUE,
    ENCRYPTION_KEY,
    IS_JIRAN: IS_JIRAN === "true",
    HMAC_SECRET_KEY,
    IS_MULTI_GROUP: IS_MULTI_GROUP === "true",
  }
}

export const env = getEnv()
console.log('env', env)
var REACT_APP_API_ENDPOINT_URL = "https://prod-backend-surfapi.surf-admin.link/api/v1/admin/";